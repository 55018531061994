import { getRechargeRecord } from "@/api";
export default {
  data() {
    return {
      pages: 1,
      num: 5,
      total: 0,
      list: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      getRechargeRecord({
        pages: this.pages,
        num: this.num
      }).then(res => {
        this.$closeToast();
        this.list = res.data;
        this.total = res.total;
      });
    },
    getTime({
      selectedValues
    }) {
      if (this.popType === 'start') {
        this.startDate = selectedValues.join('/');
      } else {
        this.endDate = selectedValues.join('/');
      }
      this.timePopShow = false;
    },
    openPop(val) {
      this.timePopShow = true;
      this.popType = val;
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.num);
    }
  }
};